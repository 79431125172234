<template>
    <div></div>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import {
	BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
	BTabs, BTab, BModal, BForm, BFormGroup, BFormInput, BFormTextarea, BCard, BCardBody, BCardHeader, BRow, BCol,
	BOverlay, BFormRadioGroup
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { computed, nextTick, onMounted, ref } from '@vue/composition-api'
import * as helper from '@/libs/helpers';
import Swal from "sweetalert2";
import "animate.css";
import moment from 'moment'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
    name: 'MaintenancesIndex',
    components: {
		BNavItemDropdown,
		BBadge,
		BMedia,
		BLink,
		BAvatar,
		VuePerfectScrollbar,
		BButton,
		BFormCheckbox,
		BTabs,
		BTab,
		BModal,
		BFormInput,
		BForm, 
		BFormGroup, 
		BFormTextarea,
		BCard, 
		BCardBody, 
		BCardHeader, 
		BRow, 
		BCol,
		BOverlay,
		BFormRadioGroup,

		//
		AppTimeline,
		AppTimelineItem,
	},
	directives:{
		Ripple,
	},
    setup() {
        
        const loading = ref(false)
        const maintenances = ref([])
        
        const isAuthenticated = () => {
			const tokenData = store.getters['auth/getTokenCloud'];
			if (!tokenData || !tokenData.access_token || !tokenData.expires_at) return false;

			// Usa Moment.js para convertir las fechas y compararlas
			const expiresAt = moment.utc(tokenData.expires_at); // Fecha de expiración en UTC
			const nowUtc = moment.utc();  // El momento actual en UTC por defecto

			// Compara las fechas
			return !expiresAt.isBefore(nowUtc); // Devuelve true si NO ha expirado
		};

        const loginInCloud = async () => {
			
			const payload = {
				grant_type: process.env.VUE_APP_API_PRICES_GRANT_TYPE,
				client_id: process.env.VUE_APP_API_PRICES_CLIENT_ID,
				client_secret: process.env.VUE_APP_API_PRICES_CLIENT_SECRET,
				scope: process.env.VUE_APP_API_PRICES_SCOPE,
			}
			
			try {			
				const { data } = await axios.post(`${process.env.VUE_APP_API_PRICES}/oauth/token`, payload, {
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
				});				

				if (data) {
					store.commit('auth/SET_TOKEN_CLOUD', data)
					localStorage.setItem('token_cloud', data.access_token)
				}
			}catch(e) {
				console.log(e)
			}
		}

        const getMaintenances = async () => {

            loading.value = true

            // Verifica si el usuario está autenticado
			if (!isAuthenticated()) {
				await loginInCloud() // Realiza el login si no está autenticado
			}

            try {
                const { data } = await axios.get(`${process.env.VUE_APP_API_PRICES}/api/client/maintenances`, {
                    headers: {
                        Authorization: `Bearer ${store.getters['auth/getTokenCloud'].access_token}`,
                        Accept: 'application/json',
                    },
                })
            }catch (error) {
                helper.handleResponseErrors(error)
            } finally {
                loading.value = false
            }
        }


        onMounted(async () => {
            await getMaintenances()
        })

        return {
            loading,
            maintenances,

            // Methods
        }
    },
}
</script>